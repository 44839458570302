import styled from '@emotion/styled'
import { FeatureFlag, LocalStorageUtil } from 'cuenect-web-core'
import { useFeatureFlags } from 'cuenect-web-core'
import { rgba } from 'emotion-rgba'
import { navigate } from 'gatsby'
import parse from 'html-react-parser'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  ContentContainer,
  InnerContainer,
  LoadingIndicator,
} from '../components'
import {
  AGENDA_SHOW_BOOKMARKS,
  DateDisplay,
  Heading,
  ImageTeaser,
  LineContainer,
  Paragraph,
  ProgramContainer,
  ProgramEntryPlaceholder,
  SieArrow,
} from '../components'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { LocalData } from './../api'
import {
  eventConfig,
  isPostEventAppointments,
  isPostEventFirst,
} from './../config'
import { mq } from './../utility/breakpoint'

const AppointmentsPage = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params
  const [loaded, setLoaded] = React.useState(false)
  const [hasEntries, setHasEntries] = React.useState(true)
  const [agendaLoaded, setAgendaLoaded] = React.useState(false)
  const { phase2, phase2b } = useFeatureFlags()

  const [selectedBookmarks, setSelectedBookmarks] = React.useState<boolean>(
    LocalStorageUtil.getString(AGENDA_SHOW_BOOKMARKS) === 'true'
  )

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  const { t } = useTranslation(['appointments', 'dialog'])

  const joinMeeting = url => {
    const userData = LocalStorageUtil.getObject<LocalData | null>('user')
      ?.person

    window.open(url)
  }

  console.log({ hasEntries })

  return (
    <PageFrame {...params} perectScroll={false} pageName="appointments">
      {!hasEntries && (
        <ContentContainer>
          <InnerContainer>
            <LineContainer className="LineContainer">
              <Heading>{t('empty.title')}</Heading>
              <Paragraph style={{ marginBottom: 0 }}>
                {parse(t('empty.subtitle'))}
              </Paragraph>
            </LineContainer>

            <Paragraph style={{ margin: '1.5rem 0' }}>
              {t('empty.copy')}
            </Paragraph>

            <Button
              onClick={() =>
                (document.location.href = '/en/dialog#appointment')
              }
            >
              {t('empty.cta')}
            </Button>
          </InnerContainer>
        </ContentContainer>
      )}

      {hasEntries && (
        <ContentContainer>
          <InnerContainer>
            <LineContainer>
              <Heading>{t('nonEmpty.title')}</Heading>
              <Paragraph style={{ marginBottom: 0 }}>
                {parse(t('nonEmpty.subtitle'))}
              </Paragraph>
            </LineContainer>

            <Paragraph style={{ margin: '1.5rem 0' }}>
              {t('nonEmpty.copy')}
            </Paragraph>

            <Button
              plain
              colored
              small
              icon={SieArrow}
              active
              onClick={() =>
                window.open(
                  'https://meet.virtualevent.siemens.com/testsession',
                  '_blank',
                  'noopener'
                )
              }
            >
              {t('nonEmpty.cta')}
            </Button>
          </InnerContainer>

          <ProgramContainer
            dateDisplay={DateDisplay.DATE_DISPLAY_GROUP}
            source={eventConfig.sources[lang]}
            showOnlyParticipations={true}
            showAppointment={true}
            hideTestSession={true}
            onLoaded={entries => {
              setAgendaLoaded(true)

              if (!entries) {
                if (hasEntries) {
                  setHasEntries(false)
                }

                return
              }

              if (entries.length > 0) {
                if (!hasEntries) {
                  setHasEntries(true)
                }
              } else if (hasEntries) {
                setHasEntries(false)
              }
            }}
          />
        </ContentContainer>
      )}
    </PageFrame>
  )
}

export default AppointmentsPage

interface StartTeaserProps {
  fullheight?: boolean
  width?: number
  align?: string
  border?: boolean
}

const EmptyTeaserContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: '2rem 0',

  [`${mq[2]}`]: {
    flexDirection: 'row',
  },
})

const EmptyTeaserImageContainer = styled.div({
  [`${mq[2]}`]: {
    flex: 30,
  },
})

const EmptyTeaserCopyContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: '1rem 0',

  [`${mq[2]}`]: {
    flex: 70,
    padding: '0 2rem',
  },
})

const StartTeaser = styled.div<StartTeaserProps>(
  ({
    theme: { colors, grid },
    fullheight,
    width,
    border = false,
    align = 'left',
  }) => `
  display:flex;
  flex-direction:column;
  ${[mq[3]]}{
    ${width && ` width: ${width}%;`}
  }
  ${
    fullheight
      ? `min-height:calc(100vh - 220px);
    ${[mq[3]]}{
      display:flex;
      align-items:center;
    }`
      : ''
  }

  ${border ? `border:1px solid ${colors.inputBorder}; padding:40px;` : ''}


  margin-top:120px;
  margin-left:${100 / grid.columns}vw;
  h1{
    margin:0;
  }
  p, .LineContainer{ margin-bottom:50px;}
`
)

const Spacer = styled.div`
  margin: 95px 0;
  ${mq[3]} {
    margin: 195px 0;
  }
`

const AgendaPlaceholder = styled.div(
  ({ theme: { colors } }) => `
  border:1px solid ${rgba(colors.tableBorder, 0.5)};
  padding:0 40px;
`
)
const LoadingIndicatorContainer = styled.div(
  ({ theme: { colors } }) => `
 height:100vh;
 display:flex;align-items:center;
 justify-content:center;
`
)
